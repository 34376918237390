<mat-dialog-content id="country-code-dialog rounded">
  <div class="container text-center ">
    <div class="row">
      <div class="col">
        <div class=" container text-center">
          <div class="row">
            <div class="col align-self-center col-sm-2">
              <button type="button" class="btn" [mat-dialog-close]="undefined">
                <mat-icon class="mt-2">close</mat-icon>
              </button>
            </div>
            <div class="col">
              <div class=" input-text-field rounded-4">
                <div class="row">
                  <div class="col col-sm-1 align-self-center" style="align-items: center;">
                    <mat-icon class="mt-2" matSuffix>search</mat-icon>
                  </div>
                  <div class="col">
                    <form>
                      <input (input)="onInputChange($event)" class="input-control" name="codeSearchController"
                        placeholder="Ingresar" type="text" [(ngModel)]="codeSearchController">
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <br>
        <div *ngFor="let country of filteredCountryFlags" class="container text-center">
          <div class="row main-contain" [mat-dialog-close]="country">
            <div class="col">
              <img width="20px" [src]="getFlagFromCountry(country.code)" alt="">
            </div>
            <div class="col">
              {{country.name}}
            </div>
            <div class="col">
              {{country.dial_code}}
            </div>
          </div>
          <div class="border border-1"></div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
import { Component, EventEmitter, Output } from "@angular/core";
import { countryCodesBank } from "../../../../core/jsons/country-codes-bank";
import { AppResourcesConfig } from "../../../../core/resources/app-resources.config";
import { CountryCode } from "../../../../domain/models/country-code";

@Component({
  selector: "app-select-country-code-dialog",
  templateUrl: "./select-country-code-dialog.component.html",
  styleUrl: "./select-country-code-dialog.component.css",
})
export class SelectCountryCodeDialogComponent {
  @Output() countryCodeEvent = new EventEmitter();

  countryFlags: CountryCode[] = [];
  filteredCountryFlags: CountryCode[] = [];

  codeSearchController: string = "";

  constructor() {
    this.countryFlags = countryCodesBank;
    this.filteredCountryFlags = this.countryFlags;
  }

  getFlagFromCountry(countryName: string): string {
    return AppResourcesConfig.countryCodeFlag(countryName.toLowerCase());
  }

  onInputChange(event: any) {
    this.filterCountryFlags(this.codeSearchController);
  }

  filterCountryFlags(text: string) {
    let filteredList: CountryCode[] = [];
    this.countryFlags.map((element) => {
      if (
        element.name.toLowerCase().includes(text.toLowerCase()) ||
        element.code.toLowerCase().includes(text.toLowerCase()) ||
        element.dial_code.toLowerCase().includes(text.toLowerCase())
      ) {
        filteredList.push(element);
      }
    });
    this.filteredCountryFlags = filteredList;
  }

  onSelectCountryCode(countryCode: CountryCode) {
    this.countryCodeEvent.emit(countryCode);
  }
}
